/**
 * 
 * Pinia Store: aktuális pénznem
 * 
 * Features:
 *  - persisted (sütiben tárolva)
 *  - magyar nyelv esetén, a default (init) érték 'HUF', más esetben 'GBP'
 * 
 * Infó a változókról:
 *  - currency:         a tényleges pénznem váltáshoz (NINCS HASZNÁLATBAN)
 *  - currencyjustinfo: informatív ár kiírásokhoz (AKTÍV)
*/

  // currency beallitasok (nuxt config)
const useConfigVariables = () => {
  const runtimeConfig = useRuntimeConfig();
  const defaultCurrencyByLanguage = runtimeConfig.public.defaultCurrencyByLanguage;
  return defaultCurrencyByLanguage
}

  // nyelvi beallitasok
const useLocaleVariable = () => {
  const { locale } = useI18n();
  return locale.value
}

export const useCurrencyStore = defineStore('currency', {
  state: () => ({
    currency: useLocaleVariable() === 'hu' ? useConfigVariables().baseLang : useConfigVariables().otherLang,
    currencyjustinfo: useLocaleVariable() === 'hu' ? useConfigVariables().baseLang : useConfigVariables().otherLang,
  }),
  persist: true,
  getters: {
    getCurrency(): string {
      return this.currency.toUpperCase();
    },
    getCurrencyJustInfo(): string {
      return this.currencyjustinfo.toUpperCase();
    },
  },
  actions: {
    setCurrency(currency: string) {
      this.currency = currency.toUpperCase();
    },
    setCurrencyJustInfo(currencyjustinfo: string) {
      this.currencyjustinfo = currencyjustinfo.toUpperCase();
    },
  },
  // actions: {
  //   async fetchTodos() {
  //     const { data }: any = await useFetch('https://jsonplaceholder.typicode.com/todos');
  //     if (data.value) {
  //       this.todos = data.value;
  //     }
  //   },
  // },
})